/* Footer.css */
.footer {
    background-color: #181818;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    position: relative; /* o 'fixed' se vuoi che rimanga visibile anche quando scorri */
    bottom: 0; /* Assicurati che sia sempre in fondo alla pagina */
    width: 100%; /* Assicura che il footer occupi tutta la larghezza */
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
}

.footer-links {
    list-style: none;
    padding: 0;
}

.footer-links li {
    display: inline;
    margin: 0 10px;
}

.footer-links a {
    color: #fff;
    text-decoration: none;
}

.footer-links a:hover {
    text-decoration: underline;
}
