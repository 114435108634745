.img-profile{
    width: 700px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
    margin-right: 5px;
    margin-left: 20px;
}

.video-intro{
    width: 600px;
}

.profile-container{
    display: flex;
    height: 100vh;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;
    justify-content: center;
}

.name-sub-container{
    color: white;
    font-family: "Saira", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    font-size: 50px;
    margin-left: 65px;
    width: 40%;
    text-align: left;
    margin-top: 20px;
}

.name-sub-container .subtitle-roles{
    font-size: 15px;
    color: grey;
    opacity: 0.7;
    margin-top: 20px;
    margin-bottom: 20px;
}

.name-sub-container .contacts-btn {
    padding: 10px 20px;
    border: 2px solid #5f1792; /* Colore del bordo */
    border-radius: 10px;
    background-color: transparent; /* Sfondo trasparente per mostrare il bordo */
    background: linear-gradient(to right, #b65bf7, #da89ff); /* Gradiente da sinistra a destra */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    font-family: "Comfortaa", sans-serif;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    margin-right: 15px;
}

.name-sub-container .statistics-container{
    display: flex;
    margin-top: 40px;
}

.name-sub-container .statistics {
    display: flex;
    align-items: center;
}

.name-sub-container .statistics .event-subtitles{
    margin-left: 10px;
    font-size: 20px;
    opacity: 0.3;
    font-weight: 500;
    width: 140px;
    line-height: 1.1;
}

.socials-vertical{
    display: flex;
    align-items: flex-end;
    color: white;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    height: 450px;
    opacity: 0.7;
}

.social-icon-middle{
    width: 40px;
    height: 40px;
    color: white;
    margin-left: 20px;
}

.social-icon-middle:hover{
    color: #da89ff;
    animation: colored 0.5s ease-out;
}

.subtitle-social{
    display: flex;
    align-items: center;
    font-size: 30px;
    color: grey;
    opacity: 0.7;
}

.rotate90{
    transform: rotate(90deg);
    color: white;
    width: 25px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.rotate90:hover{
    color: #da89ff;
    animation: colored 0.5s ease-out;
}

.intro-container{
    color: white;
    text-align: center;
}

.video-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 50px;
}

.contact-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 50px;
}

.contact-container .contact-inputs{
    width: 500px;
    height: 100%;
    border: 2px solid #5f1792;
    border-radius: 10px;
    padding: 10px;
    margin-right: 10px;
    font-family: "Comfortaa", sans-serif;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
}

.contact-container .contact-inputs input,textarea{
    width: 90%;
    height: 40px;
    border: 1px solid #5f1792;
    background: transparent;
    margin-top: 10px;
    border-radius: 2px;
    color: white;
    font-family: "Comfortaa", sans-serif;
    font-weight: bold;
    font-size: 14px;
    padding-left: 5px;
}

.contact-container .contact-inputs .contact-title{
    font-size: 25px;
    color: #da89ff;
    margin-bottom: 10px;
}

.contact-container .contact-inputs textarea{
    padding-top: 10px;
    height: 100px;
}

.contact-container .send-btn{
    padding: 10px 20px;
    border: 2px solid #5f1792; /* Colore del bordo */
    border-radius: 10px;
    background-color: transparent; /* Sfondo trasparente per mostrare il bordo */
    background: linear-gradient(to right, #b65bf7, #da89ff); /* Gradiente da sinistra a destra */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    font-family: "Comfortaa", sans-serif;
    font-weight: bold;
    font-size: 16px;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.gallery-title{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.0em;
    font-weight: 600;
    margin-bottom: 20px;
    color: #da89ff;
}

.other-title{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    font-weight: 600;
    margin-top: 20px;
    padding-right: 20px;
    color: #da89ff;
}

.call-title{
    margin-top: 20px;
}

.gallery-subtitle{
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 1.10em;
    font-weight: 400;
    margin-bottom: 20px;
    color: #da89ff;
}

.gallery-subtitle a{
    color: #da89ff;
    text-decoration: none;
    margin-left: 10px;
}

.gallery-subtitle .gallery-subtitle-active{
    color: white;
}

.gallery-subtitle a:hover{
    color: white;
}

.gallery-photo img{
    margin: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.gallery-photo img:hover{
    transform: scale(1.1);
    animation: scale-up 0.5s ease-out;
}

.gallery-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: max-content;
}

.textarea-description{
    resize: vertical;
    min-height: 60px;
    max-height: 280px;
}

/* imposta il css per tablet e smartphone */
@media screen and (max-width: 1024px) {
    .img-profile{
        width: 400px;
    }

    .profile-container{
        height: max-content;
    }

    .name-sub-container{
        font-size: 35px;
    }

    .name-sub-container .subtitle-roles{
        font-size: 15px;
    }

    .name-sub-container .contacts-btn {
        font-size: 14px;
    }

    .name-sub-container .statistics .event-subtitles{
        font-size: 18px;
    }

    .socials-vertical{
        display: none;
    }

    .social-icon-middle{
        width: 30px;
        height: 30px;
    }

    .subtitle-social{
        font-size: 25px;
        display: inline;
    }

    .rotate90{
        width: 20px;
    }

    .intro-container{
        font-size: 18px;
    }

    .video-container{
        margin-top: 40px;
        margin-bottom: 30px;
    }

    .contact-container{
        margin-top: 40px;
        margin-bottom: 30px;
    }

    .contact-container .contact-inputs{
        width: 400px;
    }

    .contact-container .contact-inputs input,textarea{
        font-size: 12px;
    }

    .contact-container .contact-inputs .contact-title{
        font-size: 20px;
    }

    .contact-container .send-btn{
        font-size: 14px;
    }

    .gallery-title{
        font-size: 1.5em;
    }

    .gallery-subtitle{
        font-size: 1.0em;
    }

    .gallery-photo img{
        margin: 5px;
    }
}

/* impostami la visione per telefoni */
@media screen and (max-width: 768px) {


    .img-profile-container{
        display: none;
    }

    .img-profile{
        width: 300px;
    }

    .video-intro{
        width: 300px;
    }

    .profile-container{
        height: max-content;
    }

    .name-sub-container{
        font-size: 30px;
        width: 80%;
    }

    .name-sub-container .subtitle-roles{
        font-size: 12px;
    }

    .name-sub-container .contacts-btn {
        font-size: 12px;
        width: 120px;
    }

    .name-sub-container .statistics .event-subtitles{
        font-size: 15px;
    }

    .socials-vertical{
        display: none;
    }

    .social-icon-middle{
        width: 25px;
        height: 25px;
    }

    .subtitle-social{
        font-size: 20px;
        display: flex;
    }

    .rotate90{
        width: 15px;
    }

    .intro-container{
        font-size: 16px;
    }

    .video-container{
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .contact-container{
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .contact-container .contact-inputs{
        width: 300px;
    }

    .contact-container .contact-inputs input,textarea{
        font-size: 10px;
    }

    .contact-container .contact-inputs .contact-title{
        font-size: 18px;
    }

    .contact-container .send-btn{
        font-size: 12px;
    }

    .gallery-title{
        font-size: 1.2em;
    }

    .gallery-subtitle{
        font-size: 0.9em;
    }

    .gallery-photo img{
        margin: 5px;
    }

    .name-sub-container .statistics-container{
        display: block;
    }

}

@keyframes colored {
    from {
        color: white;
    }
    to {
        color: #da89ff;
    }
}

@keyframes scale-up {
    from {
        transform: scale(1); /* Scala iniziale */
    }
    to {
        transform: scale(1.2); /* Scala finale */
    }
}