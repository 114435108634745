* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    height: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
    background-color: black;
    width: 100%;
    margin: 0;
    padding: 0;
}


.navbar {
    position: sticky;
    top: 0; /* Fai in modo che la navbar si attacchi quando raggiunge il top */
    display: flex;
    width: 100%;
    height: 70px;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    z-index: 1000;
    background-color: black;
}

.navbar .navbar-title {
    margin-left: 0;
    font-family: "Cinzel Decorative", serif;
    font-weight: 700;
    font-style: normal;
    font-size: 30px;
    background: linear-gradient(to right, #5f1792, #da89ff); /* Gradiente da sinistra a destra */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    margin-left: 50px;
}

.navbar .navbar-list {
    list-style: none;
    font-family: "Comfortaa", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    display: flex; /* Aggiunto per disporre gli elementi in fila */
    margin-right: 50px;
}

.navbar .navbar-list li {
    display: inline-block; /* Cambiato a inline-block per supportare position: absolute */
    padding: 10px 15px;
    color: white; /* Aggiunta di colore al testo per migliorare la leggibilità */
    position: relative; /* Aggiunto per posizionare correttamente l'elemento ::after */
    cursor: pointer;
}

.navbar .navbar-list li::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px; /* Distanza dal testo */
    width: 0;
    height: 2px; /* Spessore della linea */
    background-image: linear-gradient(to right, #a62dfd, #da89ff); /* Colore della linea */
    transition: width 1s ease;
}

.navbar .navbar-list li:hover::after {
    width: 100%;
}

/* imposta la navbar per tablet e smartphone */
@media (max-width: 768px) {
    .navbar .navbar-list {
        display: none;
    }

    .navbar .navbar-list.active {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 70px;
        right: 0;
        background-color: black;
        width: 100%;
        padding: 10px 0;
    }

    .navbar .navbar-list.active li {
        display: block;
        text-align: center;
        padding: 10px 0;
    }
}
