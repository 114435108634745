.listino-container{
    display: flex;
    height: 350px;
    width: 30%;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    margin-left: 20px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    border: 1px solid #5f1792;
    color: white;
}

.listino-container .listino-title{
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: 20px;
    color: #da89ff;
}

.listino-container .listino-table{
    padding: 5px;
    text-align: center;
}

.title-attrezzatura{
    font-size: 1.1em;
    font-weight: 600;
    color: #da89ff;
    margin-top: 10px;
}

.listino-container .listino-table ul{
    list-style: none;
}

.listino-container .listino-table ul li{
    padding-top: 5px;
}

.contacts-btn{
    margin-top: 10px;
    padding: 10px 20px;
    border: 2px solid #5f1792; /* Colore del bordo */
    border-radius: 10px;
    background-color: transparent; /* Sfondo trasparente per mostrare il bordo */
    background: linear-gradient(to right, #b65bf7, #da89ff); /* Gradiente da sinistra a destra */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    font-family: "Comfortaa", sans-serif;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.call-btn {
    margin-top: 10px;
    margin-right: 20px;
    padding: 10px 20px;
    border: 2px solid #5f1792; /* Colore del bordo */
    border-radius: 10px;
    background-color: transparent; /* Sfondo trasparente per mostrare il bordo */
    background: linear-gradient(to right, #b65bf7, #da89ff); /* Gradiente da sinistra a destra */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent; /* Rende il colore del testo trasparente */
    font-family: "Comfortaa", sans-serif;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none; /* Rimuovi la sottolineatura */
    display: inline-block; /* Assicurati che il tag <a> supporti la trasformazione */
    transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease; /* Aggiunto transform al transition */
}

.call-btn:hover {
    transform: scale(1.1);
    animation: scale-up 0.5s ease-out;
}


.contacts-btn:hover{
    transform: scale(1.1);
    animation: scale-up 0.5s ease-out;
}



.event-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    height: 100vh;
}

.contact-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

@media screen and (max-width: 1024px) {
    .listino-container{
        height: 380px;
    }
}

@media screen and (max-width: 768px) {
    .event-container{
        display: block;
        height: max-content;
    }

    .listino-container{
        width: 90%;
    }

    .call-btn{
        width: max-content;
        font-size: 12px;
    }
}

@keyframes scale-up {
    from {
        transform: scale(1); /* Scala iniziale */
    }
    to {
        transform: scale(1.1); /* Scala finale */
    }
}